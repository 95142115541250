<template>
  <v-container class="fill-heigt" fluid>
    <v-row class="justify-center" style="margin-top: -45px">
      <v-col cols="12" md="8">
        <v-alert
          v-model="alert"
          dense
          type="info"
          class="text-center caption mb-0"
          dismissible
          >{{ $t("alert_scheduled_interview") }}</v-alert
        >
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <div>
        <v-btn icon color="primary" @click="goBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-row class="d-flex flex-row justify-space-between align-center mb-2">
      <div>
        <v-select
          :items="selectInterview"
          :label="$t('scheduled_interviews')"
          item-text="text"
          item-value="key"
          dense
          solo
          style="width: 250px"
          v-model="pageInterview"
          @change="selectInterviewPage()"
        ></v-select>
      </div>
      <div class="d-flex flex-row align-center">
        <div class="text-right px-0 ml-6">
          <form v-on:submit.prevent="search()">
            <v-text-field
              style="text-decoration: captalize"
              v-model="text"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
              outlined
              @click:append="search()"
              @input="search()"
            ></v-text-field>
          </form>
          <div class="mt-5">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  small
                  @click="openScheduleInterviewDialog()"
                  style="margin-left: 5px"
                >
                  <v-icon left>mdi-calendar</v-icon
                  >{{ $t("schedule_interview") }}
                </v-btn>
              </template>
              <div v-html="$t('tooltip_interview')"></div>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-row>

    <v-row>
      <!-- <pre>{{ interview.vacancy.id }}</pre> -->
      <v-expansion-panels>
        <template v-for="intervieww in interviews.outstanding">
          <!-- <pre>{{ intervieww }}</pre> -->
          <expansion-panel
            :interviewId="$route.params.id"
            :interviews="intervieww"
            :vacancyId="interview.vacancy.id"
            :key="intervieww.timestemp"
          />
        </template>
      </v-expansion-panels>
    </v-row>

    <add-recruter-dialog />
    <add-observer-dialog />
    <re-schedule-interview />
    <finish-interview />
    <cancel-interview />
    <edit-email-dialog />
    <success-dialog />
    <options-re-schedule />
    <infoDialog />
    <warning-dialog
      :dialog="dialogWarning"
      :message="$t('IntervieWorning')"
      @close="dialogWarning = false"
    />
    <add-candidate-to-interview @added="addCandidate" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_INTERVIEW_BY_ID_QUERY } from "../graphql/Query.gql";
import { UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION } from "../graphql/Subscraption.gql";
import { groupBy } from "@/utils";
import ExpansionPanel from "../components/panel/ExpansionPanel.vue";
import AddRecruterDialog from "../components/AddRecruterDialog.vue";
import AddObserverDialog from "../components/AddObserverDialog.vue";
import ReScheduleInterview from "../components/ReScheduleInterview.vue";
import FinishInterview from "../components/FinishInterview.vue";
import CancelInterview from "../components/CancelInterview.vue";
import EditEmailDialog from "../components/EditEmailDialog.vue";
import SuccessDialog from "../components/SuccessDialog.vue";
import infoDialog from "../components/infoDialog.vue";
import OptionsReSchedule from "../components/OptionsReSchedule";
import AddCandidateToInterview from "../components/form/AddCandidateToInterview.vue";
import WarningDialog from "./../../../components/WarningDialog.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";

export default {
  name: "ScheduledInterview",
  mixins: [replaceSpecialCharsMixins],
  components: {
    ExpansionPanel,
    AddRecruterDialog,
    AddObserverDialog,
    ReScheduleInterview,
    FinishInterview,
    CancelInterview,
    EditEmailDialog,
    SuccessDialog,
    OptionsReSchedule,
    WarningDialog,
    AddCandidateToInterview,
    infoDialog,
  },

  data: () => ({
    interview: {},
    text: "",
    alert: true,
    dialogWarning: false,
    joined: false,
    pageInterview: "",
  }),

  apollo: {
    interview: {
      query: GET_INTERVIEW_BY_ID_QUERY,
      variables() {
        return { id: this.$route.params.id };
      },
      fetchPolicy: "cache-and-network",
    },
    $subscribe: {
      updateInterviewSchedule: {
        query: UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
        result({ data }) {
          this.interview = data.updateInterviewSchedule;
        },
      },
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("joined", () => {
      this.joined = true;
    });
    // eslint-disable-next-line no-undef
    Fire.$on("left", () => {
      this.joined = false;
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.joined) {
      // eslint-disable-next-line no-undef
      this.dialogWarning = true;
      next(false);
    } else {
      next(true);
    }
  },

  computed: {
    ...mapGetters({
      getBlockedCandidates: "candidate/getBlockedCandidates",
    }),
    selectInterview() {
      return [
        {
          key: "concluded_canceled_interviews",
          text: this.$t("concluded_canceled_interviews"),
        },
        { key: "approved_candidates", text: this.$t("approved_candidates") },
      ];
    },
    interviews() {
      const ZERO_NUMBER = 0;
      const blockeds = this.getBlockedCandidates;
      const candidates = this.interview.candidates;
      let list = candidates
        ? candidates.map((findCandidate) => {
            const findIndex = blockeds.findIndex(
              (blockedId) =>
                findCandidate.interviewee &&
                blockedId == findCandidate.interviewee.id
            );
            const stage = findCandidate.stages.find(
              (findStage) => findStage.name === findCandidate.currentStage
            );
            return {
              ...findCandidate,
              address: this.interview.address,
              subject: this.interview.subject,
              telephone: this.interview.telephone,
              message: this.interview.message,
              vacancy: this.interview.vacancy.title,
              vacancyId: this.interview.vacancy.id,
              timestemp: `${stage.date} ${stage.time} ${stage.groupBy}`,
              blocked: findIndex >= ZERO_NUMBER,
            };
          })
        : [];

      const completed = list.filter(
        (filterCandidate) =>
          filterCandidate.status === "rejected" ||
          filterCandidate.status === "finished" ||
          filterCandidate.status === "canceled"
      );

      list = list.filter(
        (filterCandidate) =>
          filterCandidate.status !== "approved" &&
          filterCandidate.status !== "rejected" &&
          filterCandidate.status !== "finished" &&
          filterCandidate.status !== "canceled"
      );

      if (this.text) {
        const text = this.replaceSpecialChars(this.text);
        list = list.filter((c) =>
          this.replaceSpecialChars(c.interviewee.fullName).includes(text)
        );
      }

      const outstanding = groupBy(list, "timestemp", (record, dateKey) => {
        return [record[dateKey]];
      });

      let resultItems = { outstanding, completed };

      resultItems.outstanding = groupBy(
        list,
        "timestemp",
        (record, dateKey) => {
          return [record[dateKey]];
        }
      );
      // console.log(resultItems.outstanding);
      return resultItems;
    },
  },

  methods: {
    addCandidate(candidates) {
      this.interview.candidates = candidates;
    },

    openScheduleInterviewDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("AddCandidateToInterview", this.interview);
    },

    go() {
      this.$router.push({
        name: "completed.interviews",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    selectInterviewPage() {
      console.log(this.pageInterview);
      if (this.pageInterview === "concluded_canceled_interviews") {
        this.go();
      }
      if (this.pageInterview === "approved_candidates") {
        this.$router.push({
          name: "approved.interviews",
          params: {
            id: this.$route.params.id,
          },
        });
      }
    },
    goBack() {
      // this.$router.push({
      //   name: "interviews.preview",
      //   params: {
      //     id: this.$route.params.id,
      //   },
      // });
      this.$router.push({
        name: "interviews",
      });
    },
  },
};
</script>

<style scoped>
.whirelab-conclud-folder {
  position: relative;
  display: inline-block;
}

.whirelab-conclud-folder .whirelab-conclud-tooltip {
  visibility: hidden;
  width: 280px;
  background-color: #757575;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 14px;
  padding: 5px 5px;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0;
  margin-left: -120px;
}

.whirelab-conclud-folder:hover .whirelab-conclud-tooltip {
  visibility: visible;
}
</style>
